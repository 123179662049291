import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Releases from "../views/Releases.vue";
import Stuff from "../views/Stuff.vue";
import Info from "../views/Info.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/releases",
    name: "Releases",
    component: Releases,
  },
  {
    path: "/releases/:uid",
    name: "ReleasesUid",
    component: Releases,
  },
  {
    path: "/stuff",
    name: "Stuff",
    component: Stuff,
  },
  {
    path: "/info",
    name: "Info",
    component: Info,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
