<template>
  <article :class="$style.Wrap">
    <div
      :class="$style.Inner"
      :style="{ backgroundColor: settings.highlight_color }"
    >
      <header :class="$style.Header">
        <a :href="content.author_url" target="soundcloud">
          {{ content.provider_name }}/{{ content.author_name }}
        </a>
      </header>
      <section
        v-if="content"
        :class="$style.Content"
        ref="content"
        :style="height ? { height: `${height}px` } : null"
      >
        <a :href="content.embed_url" target="soundcloud">{{ content.title }}</a>
      </section>
      <footer :class="$style.Footer">
        <a :href="content.embed_url" target="soundcloud"> listen </a>
      </footer>
    </div>
    <!-- <a :href="content.embed_url" target="insta">
      <img :src="content.thumbnail_url" />
    </a> -->
  </article>
</template>

<script>
import textFit from "textfit";
import { mapState } from "vuex";
export default {
  name: "SoundCloudCard",
  props: {
    data: Object,
  },
  data() {
    return {
      height: null,
    };
  },
  computed: {
    ...mapState(["settings"]),
    content() {
      return this.data.primary.embed;
    },
  },
  methods: {
    resizeText() {
      const heading = this.$refs.content;
      const dimensions = heading.getBoundingClientRect();
      this.height = dimensions.height;
      this.resized = true;
      try {
        this.$nextTick(() => {
          setTimeout(() => {
            textFit(heading, {
              alignVert: false,
              alignHoriz: false,
              multiLine: true,
              detectMultiLine: true,
              minFontSize: 12,
              maxFontSize: 40,
              reProcess: true,
              widthOnly: false,
              alignVertWithFlexbox: false,
            });
          }, 100);
          this.img_loaded = true;
        });
      } catch (err) {
        console.log(err);
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      const heading = this.$refs.content;
      const dimensions = heading.getBoundingClientRect();
      this.height = dimensions.height;
      this.resizeText();
    });
    window.addEventListener("resize", this.resizeText);
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeText);
  },
};
</script>

<style module lang="postcss">
.Wrap {
  @apply relative;
  @apply lg:col-span-4;
  padding-bottom: 100%;
  line-height: normal;
}

.Inner {
  @apply absolute top-0 left-0 right-0 bottom-0 w-full h-full rounded-full;
  @apply flex flex-col;
}

.Header {
  @apply font-mono border-b border-gray-900 pb-1 mt-5;
}

.Content {
  @apply flex-grow font-title border-b border-gray-900 h-full;
}

.Footer {
  @apply pt-1 mb-1 text-lg;
}
</style>
