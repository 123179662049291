<template>
  <article
    v-html="tweetHtml"
    :class="{ [$style.Wrap]: true, [$style.Dark]: theme === 'dark' }"
  />
</template>

<script>
import tweet from "tweet-html";

export default {
  name: "TweetCard",
  props: {
    data: Object,
  },
  data() {
    return {};
  },
  computed: {
    tweetHtml() {
      if (!this.data?.primary?.user) return "";
      return tweet(this.data.primary, this.data?.primary?.user.screen_name);
    },
    theme() {
      if (!this.data?.primary?.theme) return "light";
      return this.data.primary.theme;
    },
  },
};
</script>

<style module lang="postcss">
.Wrap {
  @apply relative p-3 bg-white flex flex-col flex-col-reverse;
  @apply lg:col-span-4;
  & :global(.date) {
    @apply tracking-tight  border-t border-gray-900 pt-2 mt-1;
  }
  & :global(.text) {
    @apply mb-2;
    & a {
      @apply font-title font-sans hover:bg-gray-900 hover:text-white;
    }
  }
  & :global(.photo) {
    @apply block mt-2;
  }
}

.Dark {
  @apply bg-gray-900;
  & :global(.date) {
    @apply text-white border-white;
  }
  & :global(.text) {
    @apply text-white;
    & a {
      @apply hover:bg-white hover:text-gray-900;
    }
  }
}
</style>
