<template>
  <a
    :href="purchase_url.url || '#'"
    :target="purchase_url.target || 'self'"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  >
    <article :class="$style.Wrap">
      <div ref="image" :class="$style.Artwork">
        <prismic-image :field="artwork" @load="resizeText" />
      </div>
      <section v-if="content" :class="$style.Content">
        <header
          ref="header"
          :class="$style.Header"
          :style="{ height: `${height}px` }"
        >
          <divider pad-bottom />
          <h2 v-if="artists" :class="$style.Artists">
            <template v-for="(item, i) in artists">
              <span :key="`artist-${i}`">
                {{ $prismic.asText(item.artist.data.name) }}
              </span>
            </template>
          </h2>
          <divider pad-top pad-bottom />
          <h2 :class="$style.Title">{{ $prismic.asText(title) }}</h2>
          <divider pad-top />
        </header>
        <footer :class="$style.Footer">
          <span href="">listen/buy</span>
        </footer>
      </section>
    </article>
  </a>
</template>

<script>
import textFit from "textfit";
import Divider from "@/components/Divider";

export default {
  name: "ReleaseList",
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    Divider,
  },
  data() {
    return {
      resized: false,
      width: "100%",
      height: "100%",
      hover: false,
    };
  },
  computed: {
    content() {
      return this.data;
    },
    title() {
      return this.data.title || [];
    },
    artists() {
      return this.data.artists || [];
    },
    artwork() {
      if (this.images.length < 2) return this.data.artwork.md || {};
      return this.hover
        ? this.images[this.getRandomInt(0, this.images.length)].image.md
        : this.data.artwork.md || {};
    },
    release_date() {
      return this.data.release_date || null;
    },
    purchase_url() {
      return this.data.purchase_url;
    },
    images() {
      return this.data.images || [];
    },
  },
  methods: {
    resizeText() {
      const heading = this.$refs.header;
      const dimensions = heading.getBoundingClientRect();
      this.height = dimensions.height - 60;
      this.resized = true;
      try {
        this.$nextTick(() => {
          textFit(heading, {
            alignVert: false,
            alignHoriz: false,
            multiLine: true,
            detectMultiLine: true,
            minFontSize: 12,
            maxFontSize: 80,
            reProcess: true,
            widthOnly: false,
            alignVertWithFlexbox: false,
          });
          this.img_loaded = true;
        });
      } catch (err) {
        console.log(err);
      }
    },
    onMouseEnter() {
      this.hover = true;
      console.log(this);
    },
    onMouseLeave() {
      this.hover = false;
      console.log(this);
    },
    getRandomImage() {
      return this.images[this.getRandomInt(0, this.images.length)].image;
    },
    getRandomInt(min, max) {
      console.log(min, max);
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
    },
  },
  mounted() {
    window.addEventListener("resize", this.resizeText);
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeText);
  },
};
</script>

<style module lang="postcss">
.Wrap {
  @apply relative grid items-start grid-cols-6 gap-4;
  line-height: normal;
  &:hover {
    @apply cursor-pointer;
    & .Artists,
    & .Title {
      @apply bg-gray-900 text-white;
    }
    & .Footer a {
      @apply bg-gray-900 text-white;
    }
  }
}

.Artwork {
  @apply col-span-3;
}

.Content {
  @apply col-span-3 flex flex-col h-full;
}

.Header {
  @apply flex-grow font-bold;
  & a {
    @apply block;
  }
}

.Footer {
  @apply mt-2 font-mono tracking-tight md:mt-4 text-lg;
  & span {
    @apply bg-white bg-opacity-80;
  }
}

.Artists,
.Title {
  @apply bg-white bg-opacity-80 font-title inline;
  /* line-height: 1; */
}

.Divider {
  @apply block w-3 bg-gray-900;
  height: 2px;
}

.DividerTop {
  @apply mb-1;
}

.DividerMiddle {
  @apply my-1;
}

.DividerBottom {
  @apply mt-1;
}
</style>
