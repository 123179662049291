<template>
  <div :class="$style.Grid">
    <template v-for="(item, i) in stream">
      <stuff-card :data="item.data" :key="`stuff-${i}`" />
    </template>
  </div>
</template>

<script>
import StuffCard from "@/components/StuffCard";

export default {
  name: "Stuff",
  components: {
    StuffCard,
  },
  data() {
    return {
      response: null,
    };
  },
  methods: {
    async getContent() {
      try {
        const releases = await this.$prismic.client.query(
          this.$prismic.Predicates.at("document.type", "stuff")
        );
        this.response = releases;
      } catch (err) {
        console.error(err);
      }
    },
  },
  computed: {
    stream() {
      if (!this.response) return [];
      return this.response?.results || [];
    },
  },
  created() {
    this.getContent();
  },
};
</script>

<style module lang="postcss">
.Grid {
  @apply grid items-start gap-8 md:gap-20 xs:grid-cols-2 sm:gap-10 md:gap-12;
}
</style>
