<template>
  <div :class="$style.Grid">
    <section :class="$style.List">
      <template v-for="item in list">
        <release-list :data="item.data" :key="`release-${item.uid}`" />
      </template>
    </section>
    <nav :class="$style.Nav" v-show="list">
      <div :class="$style.Dividers">
        <divider pad-bottom /><divider pad-bottom />
      </div>
      <div :class="$style.Reset">
        <router-link
          :to="'/releases'"
          :class="$style.Filter"
          :style="{ backgroundColor: settings.highlight_color }"
        >
          all artists
        </router-link>
      </div>
      <div :class="$style.Dividers">
        <divider pad-bottom pad-top /><divider pad-bottom pad-top />
      </div>
      <ul :class="$style.Filters">
        <li v-for="(item, i) in filters" :key="`filter-${i}`">
          <router-link
            :to="`/releases/${item.uid}`"
            :class="$style.Filter"
            :style="{ backgroundColor: settings.highlight_color }"
          >
            {{ $prismic.asText(item.data.name) }}
          </router-link>
        </li>
      </ul>
      <div :class="$style.Dividers"><divider pad-top /><divider pad-top /></div>
    </nav>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ReleaseList from "@/components/ReleaseList";
import Divider from "@/components/Divider";

export default {
  name: "Releases",
  components: {
    ReleaseList,
    Divider,
  },
  data() {
    return {
      response: null,
    };
  },
  methods: {
    async getContent() {
      try {
        const releases = await this.$prismic.client.query(
          this.$prismic.Predicates.at("document.type", "release"),
          {
            fetchLinks: "artist.name",
          }
        );
        const artists = await this.$prismic.client.query(
          this.$prismic.Predicates.at("document.type", "artist")
        );
        this.response = { releases, artists };
      } catch (err) {
        console.error(err);
      }
    },
  },
  computed: {
    ...mapState(["settings"]),
    filter() {
      return this.$route.params.uid;
    },
    list() {
      if (!this.response || !this.response.releases) return [];
      const releases = this.response?.releases?.results || [];
      if (this.filter) {
        const results = [];
        releases.map((item) => {
          const match = item.data.artists.find(
            (data) => data.artist.uid === this.filter
          );
          if (match) results.push(item);
        });
        return results;
      }
      releases.sort(function compare(a, b) {
        var dateA = new Date(a.data.release_date);
        var dateB = new Date(b.data.release_date);
        return dateA - dateB;
      });
      return releases.reverse();
    },
    filters() {
      if (!this.response || !this.response.artists) return [];
      return this.response?.artists?.results || [];
    },
  },
  created() {
    this.getContent(this.$route.params.uid);
  },
};
</script>

<style module lang="postcss">
.Grid {
  @apply relative z-10 grid items-start gap-4;
  @apply xs:grid-cols-8;
  @apply lg:grid-cols-12;
}

.List {
  @apply grid gap-4;
  @apply xs:col-span-5;
  @apply md:gap-8;
  @apply lg:col-span-7;
}

.Nav {
  @apply xs:col-span-3;
  @apply md:sticky md:top-32;
  @apply lg:col-start-9 lg:col-span-4;
}

.Filters {
  @apply flex flex-wrap justify-end -mb-2;
}

.Filter {
  @apply block text-left text-sm font-mono lowercase ml-2 mb-2 px-1;
  @apply hover:text-white hover:bg-gray-900 !important;
  &:global(.router-link-exact-active) {
    @apply text-white bg-gray-900 !important;
  }
}

.Reset {
  @apply flex justify-end -mb-2;
}

.Dividers {
  @apply flex justify-between;
}
</style>
