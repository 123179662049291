<template>
  <article :class="$style.Wrap">
    <a :href="content.embed_url" target="insta">
      <img :src="content.thumbnail_url" />
    </a>
  </article>
</template>

<script>
export default {
  name: "InstaCard",
  props: {
    data: Object,
  },
  data() {
    return {};
  },
  computed: {
    content() {
      return this.data.primary.embed;
    },
    layout() {
      return "embed";
    },
  },
};
</script>

<style module lang="postcss">
.Wrap {
  @apply relative;
  @apply lg:col-span-4;
}
</style>
