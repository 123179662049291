<template>
  <div to="/" :class="$style.Logo">
    <svg
      width="97"
      height="97"
      viewBox="0 0 97 97"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 0V97H97V24.249L72.751 0H0Z"
        :fill="settings.highlight_color"
      />
    </svg>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "AppLogo",
  computed: {
    ...mapState(["settings"]),
  },
};
</script>

<style module lang="postcss">
.Logo {
  @apply fixed top-0 left-0 w-20 h-20 sm:w-32 sm:h-32 md:w-48 md:h-48 md:mt-4 md:ml-4;
  & svg {
    @apply w-full h-full block;
  }
}
</style>
