<template>
  <div :class="$style.Grid">
    <template v-for="(item, i) in stream">
      <release-card
        v-if="item.slice_type === 'release'"
        :data="item"
        :class="$style.StreamItem"
        :key="`stream-${i}`"
      />
      <insta-card
        v-if="item.slice_type === 'insta'"
        :data="item"
        :class="$style.StreamItem"
        :key="`stream-${i}`"
      />
      <sound-cloud-card
        v-if="item.slice_type === 'soundcloud'"
        :data="item"
        :class="$style.StreamItem"
        :key="`stream-${i}`"
      />
      <tweet-card
        v-if="item.slice_type === 'tweet'"
        :data="item"
        :class="$style.StreamItem"
        :key="`stream-${i}`"
      />
      <boomkat-card
        v-if="item.slice_type === 'boomkat'"
        :data="item"
        :class="$style.StreamItem"
        :key="`stream-${i}`"
      />
    </template>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import ReleaseCard from "@/components/ReleaseCard";
import InstaCard from "@/components/InstaCard";
import SoundCloudCard from "@/components/SoundCloudCard";
import TweetCard from "@/components/TweetCard";
import BoomkatCard from "@/components/BoomkatCard";

export default {
  name: "Home",
  components: {
    ReleaseCard,
    InstaCard,
    SoundCloudCard,
    TweetCard,
    BoomkatCard,
  },
  data() {
    return {
      response: null,
      tweets: null,
      tweetCount: -1,
    };
  },
  methods: {
    ...mapActions(["getTweets"]),

    async getContent() {
      try {
        this.response = await this.$prismic.client.getSingle("homepage", {
          fetchLinks: [
            "release.title",
            "release.purchase_url",
            "release.artwork",
            "release.formats",
            "release.artists",
            "release.release_date",
            "release.description",
            "artist.name",
            "format.format",
          ],
        });
        this.tweets = await this.getTweets();
      } catch (err) {
        console.error(err);
      }
    },
  },
  computed: {
    stream() {
      if (!this.response) return [];
      const stream = this.response?.data?.body;
      let count = 0;
      if (this.tweets) {
        stream.map((item) => {
          if (item.slice_type === "tweet") {
            item.primary = { ...item.primary, ...this.tweets.data[count] };
            count++;
          }
        });
      }
      return stream || [];
    },
  },
  created() {
    this.getContent();
  },
};
</script>

<style module lang="postcss">
.Grid {
  @apply grid gap-8 items-start;
  @apply xs:grid-cols-2;
  @apply sm:gap-10;
  @apply md:gap-20 md:gap-12;
  @apply lg:grid-cols-12 lg:gap-24;
}

.StreamItem {
  &:nth-child(2),
  &:nth-child(3n + 3) {
    @apply lg:mt-20;
  }
  &:nth-child(5n + 5) {
    @apply lg:col-start-2;
  }
}
</style>
