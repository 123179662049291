<template>
  <div id="app" :class="$style.App">
    <div
      :class="$style.Bg"
      :style="{ backgroundImage: `url(${require('@/assets/bg.svg')})` }"
    />
    <app-logo />
    <app-header />
    <transition name="fade" mode="out-in">
      <router-view :class="$style.Content" />
    </transition>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import AppHeader from "@/components/AppHeader";
import AppLogo from "@/components/AppLogo";

export default {
  name: "App",
  components: {
    AppHeader,
    AppLogo,
  },
  mounted() {
    this.getSettings();
  },
  methods: {
    ...mapActions(["getSettings"]),
  },
};
</script>

<style>
body {
  overflow-y: scroll;
}
@font-face {
  font-family: "Apercu Bold";
  src: url("assets/fonts/Apercu Bold.woff2") format("woff2");
}
@font-face {
  font-family: "Apercu Regular";
  src: url("assets/fonts/Apercu Regular.woff2") format("woff2");
}
@font-face {
  font-family: "Suisse Mono";
  src: url("assets/fonts/Suisse BP Mono Regular.woff2") format("woff2");
}
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>

<style module lang="postcss">
.Content {
  @apply pt-16 px-4 pb-10 min-h-screen;
  @apply md:px-16 md:pt-32 md:pb-32;
  @apply lg:pt-32 lg:pl-32 lg:pb-32;
  /* max-width: 1400px; */
  /* margin: 0 auto; */
}

.Bg {
  @apply fixed top-0 right-0 w-full h-full;
}

.App {
  @apply bg-gray-200;
}
</style>
