import Vue from "vue";
import Vuex from "vuex";
import { db } from "@/db";

Vue.use(Vuex);
const UPDATE_SETTINGS = "UPDATE_SETTINGS";

export default new Vuex.Store({
  state: {
    settings: {},
  },
  mutations: {
    [UPDATE_SETTINGS](state, settings) {
      state.settings = settings;
    },
  },
  actions: {
    async getSettings({ commit }) {
      try {
        const response = await this._vm.$prismic.client.getSingle("settings");
        commit(UPDATE_SETTINGS, response.data);
      } catch (err) {
        console.error(err);
      }
    },
    async getTweets() {
      try {
        const tweets = await db.collection("twitter").doc("feed").get();
        return tweets.data();
      } catch (err) {
        console.error(err);
      }
    },
  },
  modules: {},
});
