<template>
  <a
    :href="purchase_url.url"
    :target="purchase_url.target"
    @mouseenter="onMouseOver"
    @mouseleave="onMouseOut"
  >
    <article :class="$style.Wrap">
      <div
        :class="{ [$style.Artwork]: true, [$style.ArtworkHover]: hover }"
        :style="hover ? { transformOrigin: imageTransform } : null"
      >
        <prismic-image ref="image" :field="image" @load="resizeText" />
      </div>
      <section :class="$style.Content">
        <header
          ref="header"
          :class="$style.Header"
          :style="{ height: `${height}px` }"
        >
          <divider pad-bottom />
          <h2 :class="$style.Title">{{ $prismic.asText(title) }}</h2>
          <divider pad-top />
        </header>
        <footer :class="$style.Footer">
          <button>£{{ price }}/buy</button>
        </footer>
      </section>
    </article>
  </a>
</template>

<script>
import textFit from "textfit";
import Divider from "@/components/Divider";

export default {
  name: "StuffCard",
  props: {
    data: Object,
  },
  components: {
    Divider,
  },
  data() {
    return {
      resized: false,
      width: "100%",
      height: "100%",
      imageTransform: false,
      hover: false,
    };
  },
  computed: {
    title() {
      return this.data.title || [];
    },
    price() {
      return this.data.price;
    },
    image() {
      return this.data.image.md || {};
    },
    purchase_url() {
      return this.data.purchase_url || {};
    },
  },
  methods: {
    resizeText() {
      const heading = this.$refs.header;
      const image = this.$refs.image;
      const dimensions = image.getBoundingClientRect();
      this.height = dimensions.height - 60;
      try {
        this.$nextTick(() => {
          textFit(heading, {
            alignVert: false,
            alignHoriz: false,
            multiLine: true,
            detectMultiLine: true,
            minFontSize: 12,
            maxFontSize: 80,
            reProcess: true,
            widthOnly: false,
            alignVertWithFlexbox: false,
          });
          this.img_loaded = true;
        });
      } catch (err) {
        console.log(err);
      }
    },
    onMouseOver() {
      // this.imageTransform = this.getRandomTransform();
      this.hover = true;
    },
    onMouseOut() {
      this.hover = false;
    },
    getRandomTransform() {
      const transforms = [
        "top left",
        "top right",
        "center",
        "bottom left",
        "bottom right",
      ];
      const int = this.getRandomInt(0, transforms.length);
      return transforms[int];
    },
    getRandomInt(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
    },
  },
  mounted() {
    window.addEventListener("resize", this.resizeText);
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeText);
  },
};
</script>

<style module lang="postcss">
.Wrap {
  @apply relative grid items-start grid-cols-6 gap-4;
  /* @apply bg-white; */
  line-height: normal;
  /* &:hover {
    @apply cursor-pointer;
    & .Artists,
    & .Title {
      @apply bg-gray-900 text-white;
    }
    & .Footer a {
      @apply bg-gray-900 text-white;
    }
  } */
}
.Content {
  @apply col-span-3 flex flex-col h-full;
}

.Header {
  @apply flex-grow font-bold;
}

.Footer {
  @apply font-mono mt-4 lg:mt-8;
  & button {
    @apply inline-flex bg-white bg-opacity-80 text-lg;
    @apply lg:text-xl;
  }
}

.Artwork {
  @apply lg:col-span-3;
}

.Title {
  @apply bg-white bg-opacity-80 font-title inline;
}
</style>
