import firebase from "firebase/app";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBi6khUkMIEi6qZj7Tpuw1QCjBMeR2DPVE",
  authDomain: "diagonal-b7136.firebaseapp.com",
  projectId: "diagonal-b7136",
  storageBucket: "diagonal-b7136.appspot.com",
  messagingSenderId: "865322577064",
  appId: "1:865322577064:web:a0264c851ff3ba7041269d",
};

export const db = firebase.initializeApp(firebaseConfig).firestore();
