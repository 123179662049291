<template>
  <header :class="$style.Header">
    <router-link to="/" :class="$style.Logo">
      <span class="sr-only">Diagonal Records</span>
    </router-link>
    <nav :class="$style.Nav">
      <router-link to="/releases" :class="$style.Link">releases</router-link>
      <router-link to="/stuff" :class="$style.Link">stuff</router-link>
      <router-link to="/info" :class="$style.Link">info</router-link>
    </nav>
  </header>
</template>

<script>
export default {
  name: "AppHeader",
};
</script>

<style module lang="postcss">
.Header {
  @apply fixed top-0 left-0 w-full flex justify-end z-20;
}

.Nav {
  @apply p-4 md:text-lg;
}

.Link {
  @apply font-mono ml-4;
}

.Logo {
  @apply absolute left-0 top-0 w-20 h-20 sm:w-32 sm:h-32 md:w-48 md:h-48 md:mt-4 md:ml-4;

  & svg {
    @apply w-full h-full block;
  }
}
</style>
