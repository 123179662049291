<template>
  <div :class="$style.Grid">
    <ul :class="$style.List">
      <li v-for="(item, i) in settings.social_links" :key="`social-${i}`">
        <a
          :href="item.link.url"
          :target="item.link.target"
          :class="$style.Link"
          :style="{ backgroundColor: settings.highlight_color }"
        >
          {{ item.link_text }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Info",
  components: {},
  data() {
    return {
      response: null,
    };
  },
  computed: {
    ...mapState(["settings"]),
  },
};
</script>

<style module lang="postcss">
.Grid {
  @apply flex items-start justify-center relative z-10;
}

.List {
  @apply mx-auto text-center;
  & li {
    @apply mb-3;
  }
}

.Link {
  @apply inline-flex text-center text-lg font-mono lowercase px-1;
  @apply hover:text-white hover:bg-gray-900 !important;
}
</style>
