<template>
  <div
    :class="{
      [$style.Dash]: true,
      [$style.PadTop]: padTop,
      [$style.PadBottom]: padBottom,
    }"
  />
</template>

<script>
export default {
  name: "Divider",
  props: {
    padTop: { type: Boolean, default: false },
    padBottom: { type: Boolean, default: false },
  },
};
</script>

<style module lang="postcss">
.Dash {
  @apply block w-3 bg-gray-900;
  height: 1px;
}

.PadTop {
  @apply mt-2 sm:mt-3;
}
.PadBottom {
  @apply mb-2 sm:mb-3;
}
</style>
