import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/styles/index.css";
import PrismicVue from "@prismicio/vue";
import linkResolver from "./link-resolver"; // Update this path if necessary

const accessToken = "";
const endpoint = "https://diagonal-records.cdn.prismic.io/api/v2";

Vue.use(PrismicVue, {
  endpoint,
  apiOptions: { accessToken },
  linkResolver,
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
