<template>
  <article :class="$style.Wrap" ref="wrap">
    <div :class="$style.Formats">
      <template v-for="(item, i) in formats">
        <span :key="`format-${i}`">{{ item.format.data.format }}</span>
        <span
          v-if="i !== formats.length - 1"
          :key="`add-${i}`"
          :class="$style.Add"
          >+</span
        >
      </template>
    </div>
    <a
      :href="purchase_url.url"
      :target="purchase_url.target"
      v-if="content"
      :class="$style.Content"
      @mouseenter="onMouseOver"
      @mouseleave="onMouseOut"
      @mousemove="onMouseMove"
    >
      <header
        ref="header"
        :class="$style.Header"
        :style="{ height: `${height}px` }"
      >
        <divider pad-bottom />
        <h2 v-if="artists" :class="$style.Artists">
          <template v-for="(item, i) in artists">
            <span :key="`artist-${i}`">
              {{ $prismic.asText(item.artist.data.name) }}
            </span>
          </template>
        </h2>
        <divider pad-bottom pad-top />
        <h2 :class="$style.Title">{{ $prismic.asText(title) }}</h2>
        <divider pad-top />
      </header>
      <footer :class="$style.Footer">
        <a href="">listen/buy</a>
      </footer>
    </a>
    <div
      ref="image"
      :class="{ [$style.Artwork]: true, [$style.ArtworkHover]: hover }"
    >
      <prismic-image
        :alt="$prismic.asText(title)"
        :field="content.data.artwork"
        @load="resizeText"
      />
    </div>
  </article>
</template>

<script>
import { gsap } from "gsap";

import textFit from "textfit";
import Divider from "@/components/Divider";

export default {
  name: "ReleaseCard",
  props: {
    data: Object,
  },
  components: {
    Divider,
  },
  data() {
    return {
      resized: false,
      width: "100%",
      height: "100%",
      imageTransform: false,
      hover: false,
    };
  },
  computed: {
    content() {
      return this.data.primary.release;
    },
    title() {
      return this.content.data.title || [];
    },
    artists() {
      return this.content.data.artists || [];
    },
    formats() {
      return this.content.data.formats || [];
    },
    artwork() {
      return this.content.data.artwork || {};
    },
    release_date() {
      return this.content.data.release_date || null;
    },
    purchase_url() {
      return this.content.data.purchase_url || {};
    },
  },
  methods: {
    resizeText() {
      const heading = this.$refs.header;
      const image = this.$refs.image;
      const dimensions = image.getBoundingClientRect();
      this.height = dimensions.height;
      try {
        this.$nextTick(() => {
          textFit(heading, {
            alignVert: false,
            alignHoriz: false,
            multiLine: true,
            detectMultiLine: true,
            minFontSize: 12,
            maxFontSize: 80,
            reProcess: true,
            widthOnly: false,
            alignVertWithFlexbox: false,
          });
          this.img_loaded = true;
        });
      } catch (err) {
        console.log(err);
      }
    },
    callParallax(e) {
      this.parallaxIt(e, this.$refs.image, 80);
      // parallaxIt(e, ".hamburger", 40);
    },
    parallaxIt(e, target, movement) {
      var $this = this.$el.getBoundingClientRect();
      var relX = e.layerX;
      var relY = e.layerY;

      gsap.to(target, {
        rotationX: (relX / $this.width) * 0.5,
        rotationY: (relY / $this.height) * 0.5,
        x: ((relX - $this.width / 2) / $this.width) * movement,
        y: ((relY - $this.height / 2) / $this.height) * movement,
      });
    },
    onMouseMove(e) {
      console.log(e);
      this.callParallax(e);
    },
    onMouseOver() {
      gsap.to(this.$refs.image, {
        scale: 1.3,
        duration: 0.25,
        rotationX: 0,
        rotationY: 0,
      });
      this.imageTransform = this.getRandomTransform();
      this.hover = true;
    },
    onMouseOut() {
      gsap.to(this.$refs.image, {
        scale: 1,
        x: 0,
        y: 0,
        rotationX: 0,
        rotationY: 0,
      });
      console.log("out");
      this.hover = false;
    },
    getRandomTransform() {
      const transforms = [
        "top left",
        "top right",
        "center",
        "bottom left",
        "bottom right",
      ];
      const int = this.getRandomInt(0, transforms.length);
      return transforms[int];
    },
    getRandomInt(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
    },
  },
  mounted() {
    window.addEventListener("resize", this.resizeText);
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeText);
  },
};
</script>

<style module lang="postcss">
.Wrap {
  @apply relative;
  @apply lg:col-span-6;
  line-height: normal;
  &:hover {
    & .Artists,
    & .Title {
      @apply bg-gray-900 text-white;
    }
    & .Formats span,
    & .Footer a {
      @apply bg-gray-900 text-white;
    }
  }
  &:hover {
  }
}

.Content {
  @apply absolute top-0 left-0 w-full h-full  flex flex-col z-10;
}

.Header {
  @apply flex-grow font-bold pb-10 p-3;
  @apply lg:p-4;
}

.Footer {
  @apply bottom-0 w-full px-3 py-1 font-mono;
  @apply lg:p-4;
  & a {
    @apply inline-flex bg-white bg-opacity-80 text-lg;
    @apply lg:text-xl;
  }
}

.Artwork {
  @apply lg:mr-28;
  transform-origin: center;
  /* transition: transform 0.5s; */
}

.ArtworkHover {
  /* @apply transform-gpu scale-125; */
}

.Artists,
.Title {
  @apply bg-white bg-opacity-80 font-title inline;
}

.Divider {
  @apply block w-3 bg-gray-900;
  height: 2px;
}

.DividerTop {
  @apply mb-1;
}

.DividerMiddle {
  @apply my-1;
}

.DividerBottom {
  @apply mt-1;
}

.Formats {
  @apply absolute transform -translate-y-full font-mono px-3 lg:px-4 pb-3 z-10;
}

.Add {
  @apply mx-2;
}
</style>
